//Call Account/Payment/CreateOrder and pass it the transaction details to retrieve an order ID from Paypal.
export async function createOrder(transactionDetails: string): Promise<any> {
  try {
    const response = await fetch(`/Account/Payment/CreateOrder`, {
      method: 'POST',
      body: transactionDetails,
      headers: {
        'Content-Type': 'application/json', // Ensure you send the correct headers
      },
    });

    const data = await response.json();

    return data.result; // Return the result
  } catch (error) {
    console.error('Error creating order:', error);
    throw error; // Re-throw the error so it can be handled upstream if needed
  }
}

export async function processPayment(orderId: string, ipnId: string): Promise<ReturnObject> {
  let returnObject: ReturnObject = {
    transactionState: '',
    error: null,
    orderData: null,
    status: '',
    response: null,
    details: null
  };

  try {
    const response = await fetch(`/Account/Payment/ProcessPayment?orderId=${orderId}&ipnId=${ipnId}`, {
      method: "POST",
    });

    const orderData: ReturnObject = await response.json();

    return orderData;
  } catch (error) {
    returnObject.status = 'ERROR';
    returnObject.error = { message: 'An error occurred during payment processing.' };
  }

  return returnObject;
}

//Object returned as a result of processing a payment
interface ReturnObject {
  transactionState: string;
  error: any;
  orderData: any;
  status: string;
  response: string,
  details: string
}