export default function initCharCount() {
  const textareas = document.querySelectorAll<HTMLTextAreaElement>('textarea[data-characters-recommended], textarea[data-val-length-max]');

  textareas.forEach(textarea => {

    const recommendedCharCount = textarea.dataset.charactersRecommended ? parseInt(textarea.dataset.charactersRecommended) : null;
    const maxCharCount = textarea.dataset.valLengthMax ? parseInt(textarea.dataset.valLengthMax) : null;

    if (recommendedCharCount && maxCharCount == null) return;

    const charCount = document.createElement("div");
    charCount.classList.add("char-count", "float-end", "fs-10");
    textarea.insertAdjacentElement('afterend', charCount);

    const updateCharCount = () => {
      let smiley = "Don't forget about me ☹️";
      let newlineCount = (textarea.value.match(/\n/g) || []).length;
      const length = textarea.value.length + newlineCount;


      if (recommendedCharCount !== null && length >= recommendedCharCount) {
        charCount.classList.add("text-success");
      } else {
        charCount.classList.remove("text-success");
      }

      if (maxCharCount !== null && length > maxCharCount) {
        smiley = "Too many characters! 😟";
        charCount.classList.add("text-danger");
      } else {
        charCount.classList.remove("text-danger");
        if (length == 0) {
          smiley = "Don't forget about me ☹️";
        } else if (recommendedCharCount !== null) {
          if (length < recommendedCharCount / 2) {
            smiley = "Let's add some more details! 🤔";
          } else if (length < recommendedCharCount) {
            smiley = "Looking great! 😀";
          } else {
            smiley = "That looks fab! 😀";
            charCount.classList.add("animate__animated", "animate__tada");
          }
        } else {
          smiley = "";
        }
      }

      let charCountText = `${length}`;
      if (recommendedCharCount !== null) {
        charCountText += ` / ${recommendedCharCount} characters recommended`;
      }
      if (maxCharCount !== null) {
        charCountText += `/${maxCharCount} max characters`;
      }
      charCount.textContent = `${smiley} ${charCountText}`;
    };

    textarea.addEventListener("input", updateCharCount);
    updateCharCount(); // Initial count update
  });
}
