import { savedSearches, SavedSearchCookie } from "./cookies.js";
import showModal, { MessageType } from "./showModal.js";

export default function initSavedSearches() {
  let savedSearchesContainer = document.getElementById("SavedSearches") as HTMLElement;

  if (savedSearchesContainer && savedSearches && savedSearches.length > 0) {
    savedSearches.forEach(element => {

      savedSearchesContainer.appendChild(createSavedSearchElement(element));
    });
  }

  initSaveThisSearch()
}

function createSavedSearchElement(savedSearch: SavedSearchCookie) {
  let li = document.createElement("li") as HTMLLIElement;
  li.id = `SavedSearch_${savedSearch.SavedSearchId}`;
  li.classList.add("list-group-item");

  let link = document.createElement("a") as HTMLAnchorElement;
  link.href = savedSearch.Url;
  link.target = "_blank";
  link.innerHTML = `<i class="far fa-play"></i> ${savedSearch.Name}`;

  let deleteButton = document.createElement("a") as HTMLAnchorElement;
  deleteButton.href = `/SavedSearches/Remove/${savedSearch.SavedSearchId}`;
  deleteButton.title = "Delete this search";
  deleteButton.classList.add("float-end");
  deleteButton.innerHTML = `<i class="far fa-trash-alt"></i>`;


  deleteButton.addEventListener("click", (e) => {
    e.preventDefault();
    if (!confirm("Are you sure you want to delete this search?")) {
      return;
    }
    fetch(deleteButton.href, { method: "POST", headers: { "X-Requested-With": "XMLHttpRequest" } })
      .then((response) => response.json())
      .then(data => {
        if (data.body) {
          if (data.type == 2 /*success*/) {
            li.remove();
          } else {
            showModal(data.title, data.body);
          }
        }
      });
  });

  li.appendChild(link);
  li.appendChild(deleteButton);
  return li;
}

function initSaveThisSearch() {
  // Select the form
  const form = document.getElementById('frmSearches') as HTMLFormElement;
  let formSubmitButton = document.getElementById("btnSave");


  if (!form) {
    console.error('Form with ID "frmSearches" not found.');
    return;
  }

  // Listen for the form's submit event
  form.addEventListener('submit', (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // Create a FormData object from the form
    const formData = new FormData(form);

    // Parse the current URL's search parameters
    const urlParams = new URLSearchParams(window.location.search);

    // Add each URL parameter to the FormData object
    urlParams.forEach((value, key) => {
      formData.append(key, value);
    });

    // Prepare the form submission via fetch API
    const actionUrl = form.action || window.location.href; // Use form action or current URL

    fetch(actionUrl, {
      method: 'POST', // Assuming POST; change to GET if needed
      body: formData
    })
      .then((response) => {
        if (response.ok) {
          console.log('Form submitted successfully!');
          window.location.reload();
        } else {
          console.error('Form submission failed:', response.statusText);
          alert("Search failed to save.");
        }
      })
      .catch((error) => {
        console.error('Error during form submission:', error);
      });
  });
}