import { showAuthorisedElements } from './initMisc.js';
import initShareButtons from './initShareButtons.js';
import initFavourites from './initFavourites.js';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { mapStyles } from './mapStyles.js';


declare const GLightbox: any;
declare const htmx: any;
let map;
let movingInterval;
let stopTimeout;
let markersNotSpotlightedArray = [];
let markersAllArray = [];
let iconSize = 5;
let spotlightIconSize = 4;
let maxZoom = 9;

export default async function initMaps() {
  let mapDiv = document.getElementById("listings-map");
  if (!mapDiv) return;
  let mapZoom = parseFloat(mapDiv.getAttribute("data-zoom"));
  //Initialise the map.
  map = new google.maps.Map(mapDiv, {
    zoom: mapZoom ?? 3,
    center: { lat: 0, lng: 0 },
    mapId: mapDiv.dataset.mapid,
    maxZoom: maxZoom,
    minZoom: 2
  });

  //Initialise the map markers.
  let mapMarkers = document.querySelectorAll("#map-markers .map-marker");
  initMarkers(mapMarkers, map);
}

// Set up each map marker to show a job partial view when clicked
function initMarkers(markers: NodeListOf<Element>, map: any) {

  const mapContentContainer = document.getElementById("map-content-container");
  const mapLoadingIndicator = document.getElementById("map-loading-indicator");

  let listingMarker = new window.google.maps.marker.PinElement({ borderColor: "green", background: "green" }).element;
  let spotlightedMarker = new window.google.maps.marker.PinElement({ borderColor: "blue", background: "blue" }).element;

  Array.from(markers).forEach(marker => {
    let lat = parseFloat(marker.getAttribute("data-lat"));
    let lng = parseFloat(marker.getAttribute("data-lng"));
    let draggable = marker.getAttribute("data-draggable");
    let title = marker.getAttribute("data-title");
    let ajax = marker.getAttribute("data-ajax");
    let listingId = marker.getAttribute("data-listing-id");
    let isSpotlighted = marker.getAttribute("spotlighted");
    const mapMarker = new window.google.maps.marker.AdvancedMarkerElement({
      position: new window.google.maps.LatLng(lat, lng),
      map: map,
      gmpDraggable: draggable != null,
      title: title,
      zIndex: isSpotlighted ? 1000 : 500,
      content: createIcon(isSpotlighted != null),
    });
    if (isSpotlighted == null)
      markersNotSpotlightedArray.push(mapMarker);


    markersAllArray.push(mapMarker);

    if (ajax) {
      mapMarker.addListener('click', async () => {

        // Show the loading indicator
        mapLoadingIndicator.classList.remove("d-none");

        // We got this far, so now get the job info from the server.

        // Track the click on this listing
        fetch(`/AjaxHelper/UpdateClickCount?listingId=${listingId}`);

        let jobPartial = await fetch(ajax);
        let toJson = await jobPartial.json();

        // Now hide the indicator again.
        mapLoadingIndicator.classList.add("d-none");

        if (toJson.modalMessage) {

          // Hide the map search filters when the job partial view window is open
          let mapSearchFilters = document.getElementById("map-search-filters");
          mapSearchFilters.classList.add("d-none");

          // Add the job details view to the page and set the container open url to the current ajax url.
          mapContentContainer.innerHTML = toJson.modalMessage;
          mapContentContainer.setAttribute("data-open-url", ajax);

          // This shows authorised elements like apply button on the partial view.
          showAuthorisedElements();

          // This initiates the photo lightbox on the job partial view if there are photos for that job.
          let lightbox = GLightbox({
            closeOnOutsideClick: true
          });

          // This initiates the favourites button
          let favouriteButton = mapContentContainer.querySelector(".favourite-button");
          htmx.process(favouriteButton);
          initFavourites();

          initShareButtons();

          // Set up the close button on the partial view.
          let closeButton = document.getElementById("job-slim-mode-close-button");
          closeButton.addEventListener("click", () => {
            let jobDetailsWindow = document.getElementById("job-details-window");
            mapSearchFilters.classList.remove("d-none");
            jobDetailsWindow.classList.add("d-none");
          });
        }
      });
    }
  });


  //Centers the map to the markers.
  centerMapToMarkers(map, markersAllArray);

  // Create a marker clusterer
  if (markersAllArray.length > 0) {
    //const algo = new SuperClusterAlgorithm({ maxZoom: (maxZoom - 1), minPoints: 5 })
    //new MarkerClusterer({ map, markers: markersAllArray, algorithm: algo });
  }
}
function centerMapToMarkers(map: google.maps.Map, markers: google.maps.marker.AdvancedMarkerElement[]): void {
  if (markers.length === 0) {
    return;
  }

  // Create a new LatLngBounds object
  const bounds = new google.maps.LatLngBounds();

  // Extend the bounds to include each marker's position
  markers.forEach(marker => {
    bounds.extend(marker.position as google.maps.LatLng);
  });
  // Center the map to the bounds center
  map.setCenter(bounds.getCenter());
  map.fitBounds(bounds);
}

function createIcon(spotlighted: boolean) {
  const content = document.createElement("div");
  content.classList.add("map-icon");

  const icon = document.createElement("i");
  icon.classList.add("fas", "fa-location-dot");
  //icon.style.rotate = 45 + "deg");
  content.appendChild(icon);

  if (spotlighted) {
    icon.classList.add("text-info");
    icon.classList.add(`fs-${spotlightIconSize}`);
  } else {
    icon.classList.add("text-success");
    icon.classList.add(`fs-${iconSize}`);
  }
  return content;
}