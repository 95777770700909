export default function initSubmitButtons(): void {
  const forms = document.querySelectorAll('form');

  forms.forEach(form => {
    form.addEventListener('submit', event => {
      let submitButton = event.submitter;

      if (submitButton) {
        // Create the spinner element
        const spinner = document.createElement('span');
        spinner.className = 'ms-1 spinner-border spinner-border-xs';

        submitButton.appendChild(spinner);

        // Optionally, you could disable the submit button to prevent multiple submissions
        if (submitButton instanceof HTMLButtonElement || submitButton instanceof HTMLInputElement) {
          submitButton.classList.add("disabled");
        }

        // If the page is loaded from the cache
        window.addEventListener("pageshow", (e) => {
          if (e.persisted) {
            submitButton.removeChild(spinner)
            submitButton.classList.remove("disabled");
          }
        });
      }
    });
  });
}