export default function initShareButtons() {
  let buttons = document.getElementsByClassName("share-button");

  Array.from(buttons).forEach(button => {
    button.addEventListener("click", (e) => {

      let target = e.target as HTMLElement
      let shareUrl= target.dataset.shareUrl;
      let shareTitle = target.dataset.shareTitle;
      let shareText = target.dataset.shareText;
      navigator.share({ url: shareUrl, title: shareTitle, text: shareText });
    });
  });
}