import { favourites } from './cookies.js';

export default function initFavourites() {
  if (!favourites) return;

  let favouriteButtons = document.getElementsByClassName("favourite-button");
  Array.from(favouriteButtons).forEach(button => {
    let favouriteButtonId = parseInt(button.getAttribute("data-favourite-id"));
    if (favourites.includes(favouriteButtonId)) {
      button.firstElementChild.classList.add("fas");
    }
  });
}
