import { userAlerts, removeAlertFromCookie } from './cookies.js'
import showModal, { MessageType } from './showModal.js';

export default function initUserAlerts() {
  if (userAlerts == null || userAlerts != null && userAlerts.length == 0) {
    return;
  }

  userAlerts.forEach((alert) => {
    showModal(alert.Title, alert.Message, MessageType[alert.Type]);
    removeAlertFromCookie(alert.UserAlertId);
  });
}