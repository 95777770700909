import { dontSubmitEmptyFormInputs } from "./formTools";

export default function initSearchForm() {
  let searchForm = document.getElementById("frmSearch") as HTMLFormElement;
  if (!searchForm) return;

  let onMapPage = window.location.pathname.toLowerCase() == "/map";
  if (onMapPage) {
    searchForm.action = "/map";
  }


  let submitButton = searchForm.querySelector("button[type='submit']") as HTMLButtonElement;
  let sortAscButton = document.getElementById("btnSortOrderAsc") as HTMLButtonElement;
  let sortDescButton = document.getElementById("btnSortOrderDesc") as HTMLButtonElement;

  dontSubmitEmptyFormInputs(searchForm);

  //Submit form when user has changed sort field
  let sortFieldList = document.getElementById("SortFieldList") as HTMLSelectElement;
  if (sortFieldList) {
    sortFieldList.addEventListener("change", () => {
      let value = sortFieldList.value;
      let sortField = document.getElementById("SortField") as HTMLInputElement;
      sortField.value = value;
      sortOrder.value = "Desc";
      if (sortField.value.toLowerCase() == "distance") {
        sortOrder.value = "Asc";
      }
      submitButton.click();
    })
  }

  const urlParams = new URLSearchParams(window.location.search);
  const sortOrderVal = urlParams.get('SortOrder')

  if (sortOrderVal != null && sortOrderVal.toLowerCase() == "desc") {
    sortAscButton.classList.remove("btn-secondary");
    sortAscButton.classList.add("btn-outline-secondary");
  } else if (sortOrderVal != null && sortOrderVal.toLowerCase() == "asc") {
    sortDescButton.classList.remove("btn-secondary");
    sortDescButton.classList.add("btn-outline-secondary");
  }

  let sortOrder = document.getElementById("SortOrder") as HTMLInputElement;
  if (sortAscButton && sortDescButton) {
    [sortAscButton, sortDescButton].forEach(button => {
      button.addEventListener("click", (e) => {
        let value = e.currentTarget === sortAscButton ? "Asc" : "Desc";
        sortOrder.value = value;
        submitButton.click();
      })
    });
  }
}

