declare const Croppie: any;
declare const bootstrap: any;
export default function initUploadPhoto() {
  const input = document.getElementById('upload-profile-picture') as HTMLInputElement;
  if (!input) return;

  let croppieInstance: Croppie | null = null;
  const uploadButton = document.getElementById('upload-button') as HTMLButtonElement;
  const rotateButton = document.getElementById('rotate-button') as HTMLButtonElement;
  const removeProfilePhotoButton = document.getElementById('remove-profile-photo-button') as HTMLButtonElement;
  let profileImages = document.getElementsByClassName('profile-image')

  if ((profileImages[0] as HTMLImageElement).src.includes("avatar.webp")) {
    removeProfilePhotoButton.classList.add("d-none");
  }

  input.addEventListener('change', function (event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      const reader = new FileReader();
      reader.onload = function (e: ProgressEvent<FileReader>) {
        const dataURL = e.target?.result;
        if (typeof dataURL === 'string') {
          if (croppieInstance) {
            croppieInstance.destroy();
          }
          const el = document.getElementById('croppie-container')!;
          croppieInstance = new Croppie(el, {
            viewport: { width: 200, height: 200, type: 'square' },
            boundary: { width: 300, height: 300 },
            showZoomer: true,
            enableResize: true,
            enableOrientation: true
          });
          croppieInstance.bind({
            url: dataURL
          });
        }
      };
      reader.readAsDataURL(file);
    }
  });

  rotateButton.addEventListener("click", (e) => {
    if (croppieInstance)
      croppieInstance?.rotate(90);
  });


  if (removeProfilePhotoButton != null) {
    removeProfilePhotoButton.addEventListener("click", (e) => {

      if (!confirm("Delete Profile Photo?")) return;

      e.preventDefault();
      removeProfilePhotoButton.classList.add("disabled");
      fetch('/Account/Photos/RemoveProfilePhoto', {
        method: 'POST'
      }).then(data => {
        if (data) {
          removeProfilePhotoButton.innerHTML = "Profile photo removed.";

          //Photo has been uploaded, replace the profile image with the new one
          Array.from(profileImages).forEach((element: HTMLImageElement) => {
            element.src = "/assets/img/team/avatar.webp";
          });
        }
      })
        .catch(error => {
          removeProfilePhotoButton.innerHTML = "Failed to remove profile photo.";
        });
    });
  }

  uploadButton.addEventListener('click', function () {
    croppieInstance?.result({
      type: 'blob',
      size: 'viewport'
    }).then(function (blob: Blob) {
      const formData = new FormData();
      formData.append('file', blob);

      fetch('/Account/Photos/UploadProfilePhoto', {
        method: 'POST',
        body: formData
      }).then(function (response: Response) {
        return response.json();
      }).then(function (data: any) {
        console.log(data);

        if (data.url) {
          //Photo has been uploaded, replace the profile image with the new one
          Array.from(profileImages).forEach((element: HTMLImageElement) => {
            element.src = data.url;
          });

          //Close the modal
          let modalElement = document.getElementById('uploadPhotoModal');
          if (modalElement) {
            let modal = bootstrap.Modal.getInstance(modalElement!);
            modal.hide();
          }
          if (removeProfilePhotoButton != null) {
            removeProfilePhotoButton.classList.remove("disabled");
            removeProfilePhotoButton.classList.remove("d-none");
          }
        }
        // Optionally, update the UI or give feedback to the user here
      }).catch(function (error: any) {
        console.error('Error:', error);
        alert(`An error occurred uploading the photo. Please try again or contact Y&G support. Error: ${error}`);
      });
    });
  });

}