declare const Choices: any;
export default function initLocationSearchInputs(inputElement: Element | null) {
  let inputs;
  if (inputElement == null) {
    inputs = document.getElementsByClassName("location-input-select");
  } else {
    inputs = [inputElement];
  }

  if (inputs.length == 0) return;

  for (let i = 0; i < inputs.length; i++) {
    
    let country;
    if (inputElement != null) {
      country = document.getElementById(inputElement.id.replace("PostalCodeId", "Country")) as HTMLInputElement;
    } else {
      country = document.getElementById(`Addresses_${i}__Country`) as HTMLInputElement;
      if (country == null) {
        country = document.getElementById(`PreferredLocations_${i}__Country`) as HTMLInputElement;
      }
    }
    if (!country) {
      country = document.getElementById("Country") as HTMLInputElement;
    }

    let input = inputs[i];
    let id = input.id;
    let debounceTimeout;
    let debounceDelay = 300; // 300 ms delay

    let options = {
      searchPlaceholderValue: "Postcode/Location",
      noChoicesText: "Start typing a postcode",
      searchResultLimit: 20,
      removeItemButton: true
    };


    let newChoice = new Choices("#" + id, options);

    country.addEventListener("change", function (e) {
      newChoice.removeActiveItems();
    });

    newChoice.passedElement.element.addEventListener("search", function (e) {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(async function () {
        let searchVal = e.detail.value;
        if (searchVal.length <= 2) return;

        let data = await fetch(`/AjaxHelper/Select2PlaceNamesOrPostalCodesAsync?q=${searchVal}&country=${(country).value}`)
          .then(response => response.json())
          .then(data => {
            console.log(data);
            return data.items;
          })
          .catch(error => {
            console.error('Error fetching data: ', error);
            return [];
          });


        newChoice.setChoices(data, 'value', 'label', true);
      }, debounceDelay);
    });
  }
}