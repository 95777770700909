declare const _: any;
import { uniq } from 'lodash';

//Increments the click count and impression count for listings on the page.
//Click counts for job markers on the job map are tracked in initMaps.ts
export default function initClickImpressionCounts() {
  let impressionIds = [];
  document.querySelectorAll("[data-track-click]").forEach((element: HTMLElement) => {

    let id = element.getAttribute("data-track-click");
    impressionIds.push(id);
    element.addEventListener("click", function (e: CustomEvent) {
      let target = e.target as HTMLElement;
      fetch(`/AjaxHelper/UpdateClickCount?listingId=${id}`);
    });
  });

  //lodash unique array
  if (impressionIds != null && impressionIds.length > 0) {
    let uniqueArray = uniq(impressionIds);
    if (uniqueArray.length == 0) return;
    fetch(`/AjaxHelper/UpdateImpressionCount?listingIds=${uniqueArray.join("&listingIds=")}`);
  }
}