//import { PaymentsClient, PaymentDataRequest, PaymentData } from '@types/googlepay';
import { completePayment } from "./initPayment.js";
import { createOrder, processPayment } from './paypalHelpers.js';
import TransactionDetails from './transactionDetails.js';
import { notifyBot } from '../initMisc.js';
declare const paypal: any;
declare const google: any;
let googlePayClient = null;
let googlePayConfig = null;
let googlePayTransactionDetails: TransactionDetails = null;

let ipnIdElement = document.getElementById("payment-ipnId") as HTMLInputElement;
let ipnId;
//Initial objects
export default function initGooglePay(transactionDetails: any) {
    const script = document.createElement('script');
    googlePayTransactionDetails = JSON.parse(transactionDetails);
    let paymentSource = {
        google_pay: {
            attributes: {
                verification: {
                    method: "SCA_WHEN_REQUIRED"
                }
            }
        }
    };
    googlePayTransactionDetails.payment_source = paymentSource;
    console.log(googlePayTransactionDetails);
    script.src = "https://pay.google.com/gp/p/js/pay.js";
    script.async = true;
    script.onload = onGooglePayLoaded;
    document.head.appendChild(script);
}
interface returnObject {
    transactionState: string,
    error: any,
    orderData: any,
    status: string
}

//Called when the Google Pay script has loaded
async function onGooglePayLoaded() {
    //Google Pay start point. This is called when the Google Pay script has loaded.
    console.log("Google Pay has loaded!");

    let ipnIdElement = document.getElementById("payment-ipnId") as HTMLInputElement;
    ipnId = ipnIdElement.value;


    //STEP ONE: Create a google payments client.
    const paymentClient = getGooglePaymentsClient();

    //STEP TWO: Check if Google Pay is available on the device.
    const { allowedPaymentMethods, apiVersion, apiVersionMinor } = await getGooglePayConfig();
    paymentClient.isReadyToPay({ allowedPaymentMethods, apiVersion, apiVersionMinor })
        .then(function (response) {
            if (response.result) {
                addGooglePayButton();
            }
        });

}

//Retrieve the Google Payments Client. Only one instance should be created.
function getGooglePaymentsClient() {
    try {
        console.log("===Getting Google Payments Client===");
        if (googlePayClient == null) {
            let client = new google.payments.api.PaymentsClient({
                environment: 'PRODUCTION',
                merchantName: 'YardandGroom',
                merchantId: 'BCR2DN4TS7K35BA7',
                paymentDataCallbacks: { onPaymentAuthorized: onPaymentAuthorisedNew }
            });
            console.log("===Got Google Payments Client===");
            console.log(client);
            return client;
        } else {
            return googlePayClient;
        }

    } catch (e) {
        console.log("===ERROR Getting Google Payments Client===");
        console.log(e);
    }
}

async function onPaymentAuthorisedNew(paymentData) {
    let paymentStep = "";
    try {
        console.log("==Google Payment Authorised==");
        paymentStep = "Google Payment Authorised;"
        console.log("Payment Data:");
        console.log(paymentData);
        let paypal_order_id;

        //Create an order id
        console.log("==Creating Google Pay Paypal Order Id==");
        paymentStep = "Creating Google Pay Paypal Order Id";
        let createOrderResult = await createOrder(JSON.stringify(googlePayTransactionDetails));
        console.log(`==Google Pay Order ID ${createOrderResult.Id} Created==`);
        console.log(createOrderResult);
        paypal_order_id = createOrderResult.Id;

        //Confirm the order with Google
        console.log("==Confirming the order with Google Pay==");
        paymentStep = "Confirming the order with Google Pay";
        let confirmOrderResult = await paypal.Googlepay().confirmOrder({
            orderId: paypal_order_id,
            paymentMethodData: paymentData.paymentMethodData
        });

        console.log("==Confirm Order Result==");
        console.log(confirmOrderResult);

        //Google Payment order approved, so now start processing the payment
        if (confirmOrderResult.status == "APPROVED") {
            console.log("==Processing Google Pay Payment==");
            paymentStep = "Processing Google Pay Payment";

            //console.log("FORCING INITIATION OF PAYER ACTION");
            //let payerActionResult = await paypal.Googlepay().initiatePayerAction({ orderId: paypal_order_id })

            let processPaymentResult = await processPayment(paypal_order_id, ipnId);

            if (processPaymentResult.status == "COMPLETED") {
                console.log("==Google Payment Success==");
                paymentStep = "Google Payment Success";
            } else {
                console.log("==Google Payment Failure==");
                paymentStep = "Google Payment Failure";
            }
            console.log(processPaymentResult);
            completePayment(processPaymentResult, ipnIdElement.value);
        }

        //Google Payment order requires a payer action
        else if (confirmOrderResult.status == 'PAYER_ACTION_REQUIRED') {
            console.log("==Google Pay Payer Action Required==");
            paymentStep = "Google Pay Payer Action Required";
            //Initiate Google Payer Action
            let payerActionResult = await paypal.Googlepay().initiatePayerAction({ orderId: paypal_order_id })
            console.log("==Payer Action Result==");
            console.log(payerActionResult);

            //Retrieve order
            paymentStep = "Retrieving Google Pay order";
            const orderResponse = await fetch(`/Account/Payment/GetOrder/${paypal_order_id}`, {
                method: "GET"
            });
            let result = orderResponse.json();

            //Payer Action Complete. Now start processing the payment
            paymentStep = "Processing Google Pay Payment";
            let processPaymentResult = await processPayment(paypal_order_id, ipnId);

            if (processPaymentResult.status == "COMPLETED") {
                console.log("==Google Payment Success==");
                paymentStep = "Google Payment Success";
            } else {
                console.log("==Google Payment Failure==");
                paymentStep = "Google Payment Failure";
            }

            completePayment(processPaymentResult, ipnIdElement.value);
        }

    } catch (e) {
        console.log("==Google Pay Payment Failed==");
        console.log(e);
        notifyBot(`Google Pay payment failed on step:\n${paymentStep}\n\nError Details:\n${e}`);
    }
}

//Retrieve the Google Pay Config from Paypal.
async function getGooglePayConfig() {
    try {
        if (googlePayConfig == null) {
            console.log("===Getting Google Pay Config===");
            let config = await paypal.Googlepay().config();
            console.log("=== Got Google Pay Config ===");
            console.log(config);
            return config;
        }
        else {
            return googlePayConfig;
        }

    } catch (e) {
        console.log("===ERROR Getting Google Pay Config===");
        console.log(e);
    }
}

//Generated the Google Pay button.
function addGooglePayButton() {
    try {

        let paymentMethodsContainer = document.getElementById("payment-methods");
        let div = document.createElement("div");
        div.id = "googlepay-button-container";
        paymentMethodsContainer.appendChild(div);

        console.log("===Adding Google Pay Button===");
        const paymentsClient = getGooglePaymentsClient();
        const button =
            paymentsClient.createButton({
                onClick: onGooglePaymentButtonClicked,
                buttonSizeMode: 'fill',
                buttonColor: 'white',
            });

        div.appendChild(button);
        console.log("===Added Google Pay Button===");
    } catch (e) {
        console.log("===ERROR Adding Google Pay Button===");
        console.log(e);
    }

}

//Google Pay button clicked
async function onGooglePaymentButtonClicked() {
    console.log("==Google Payment button clicked==");
    const paymentDataRequest = await getGooglePaymentDataRequest();
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.loadPaymentData(paymentDataRequest);
}

async function getGooglePaymentDataRequest() {
    console.log("===Getting Google Payments Data Request===");
    const { allowedPaymentMethods, merchantInfo, apiVersion, apiVersionMinor, countryCode } = await getGooglePayConfig();
    const baseRequest = {
        apiVersion,
        apiVersionMinor
    }
    const paymentDataRequest = Object.assign({ allowedPaymentMethods: null, transactionInfo: null, merchantInfo: null, callbackIntents: null }, baseRequest);
    allowedPaymentMethods[0].parameters.billingAddressParameters = { format: "MIN" };
    allowedPaymentMethods[0].parameters.billingAddressRequired = true;
    allowedPaymentMethods[0].parameters.allowedAuthMethods = ["PAN_ONLY", "CRYPTOGRAM_3DS"];
    paymentDataRequest.allowedPaymentMethods = allowedPaymentMethods;
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo(countryCode);
    paymentDataRequest.merchantInfo = merchantInfo;
    paymentDataRequest.merchantInfo.merchantName = "YardandGroom";

    paymentDataRequest.callbackIntents = ["PAYMENT_AUTHORIZATION"];

    console.log("Created Payment Data Request:");
    console.log(paymentDataRequest);

    return paymentDataRequest;
}

//Retrieve Transaction Info for payment. Get this from PaypalComplete page!
function getGoogleTransactionInfo(countryCode) {
    const subtotal = (document.getElementById('payment-subtotal') as HTMLInputElement).value;
    const tax = (document.getElementById('payment-tax') as HTMLInputElement).value;
    const total = (document.getElementById('payment-total') as HTMLInputElement).value;
    const currency = (document.getElementById('payment-currency') as HTMLInputElement).value;
    const country = (document.getElementById('payment-country') as HTMLInputElement).value;

    return {
        displayItems: [{
            label: "Subtotal",
            type: "SUBTOTAL",
            price: subtotal,
        },
        {
            label: "Tax",
            type: "TAX",
            price: tax,
        }
        ],
        countryCode: countryCode,
        currencyCode: currency,
        totalPriceStatus: "FINAL",
        totalPrice: total,
        totalPriceLabel: "Total"
    };
}