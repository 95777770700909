export default function initBusinessAds() {
  let isJobSearch = document.getElementById('Job-search') as HTMLInputElement;

  let isJobseekerSearch = document.getElementById('Jobseeker-search') as HTMLInputElement;

  if (!isJobSearch && !isJobseekerSearch) return;

  let bannerBannerAdsContainer = document.getElementById('business-banner-ads-container') as HTMLElement;
  let businessAds = document.querySelectorAll('.businessads');

  if (isJobSearch) {

    businessAds.forEach((ad) => {
      if (ad.classList.contains("businessad-section-jobseeker"))
      {
        ad.classList.add("d-none");
      }
    });

    if (bannerBannerAdsContainer && bannerBannerAdsContainer.querySelectorAll(".businessad-section-job").length == 0) {
      bannerBannerAdsContainer.classList.add("d-none");

    }
  }

  if (isJobseekerSearch) {

    businessAds.forEach((ad) => {
      if (ad.classList.contains("businessad-section-job")) {
        ad.classList.add("d-none");
      }
    });

    if (bannerBannerAdsContainer && bannerBannerAdsContainer.querySelectorAll(".businessad-section-jobseeker").length == 0) {
      bannerBannerAdsContainer.classList.add("d-none");

    }
  }

  let businessAdsContainer = document.querySelectorAll('.business-ads-container') as NodeListOf<Element>;
  let featureAdsContainer = document.querySelectorAll('.feature-ads-container') as NodeListOf<Element>;

  for (const element of businessAdsContainer) {
    if (element.childElementCount == 0) {
      element.classList.add("d-none");
      element.nextElementSibling.classList.add("col-12");
    }
  }

  for (const element of featureAdsContainer) {
    if (element.childElementCount == 0) {
      element.classList.add("d-none");
      element.previousElementSibling.classList.add("col-12");
    }
  }
}