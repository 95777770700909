export default function initCountryRegionInputs(countryField?: HTMLInputElement) {

  let countryFields: [HTMLInputElement] | NodeListOf<Element>;
  //Initialise the region fields which are based on the country field next to them.
  countryFields = countryField ? [countryField] : document.querySelectorAll("select[name*='Country'");
  //let countryFields = document.querySelectorAll("select[name*='Country'");
  if (!countryFields) return;

  countryFields.forEach(field => {

    if (countryField == field && countryField.childElementCount == 0) {
      populateCountryField(field).then(() => {
        //addFlagsToCountryField(field);
      });
    } else {
      addFlagsToCountryField(field);
    }

    let currentCountry = (field as HTMLInputElement).value;
    const regionField = field.closest(".row").querySelector("select[name*='Region'") as HTMLInputElement;

    //addFlagsToCountryField(field);

    

    if (regionField) {
      updateRegionField(regionField, currentCountry);
      field.addEventListener("change", function (e) {
        let country = (e.target as HTMLInputElement).value;
        updateRegionField(regionField, country);
      });
    }
  });
}
function updateRegionField(regionField: HTMLInputElement, country: string) {
  let regionInUrl = new URL(window.location.href).searchParams.get("Region");
  let countryInUrl = new URL(window.location.href).searchParams.get("Country");
  let selectedRegionValue = regionField.value;

  fetch(`/AjaxHelper/LoadListAsync/Region?parms=${country}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => response.json()).then(data => {

    while (regionField.firstChild) {
      regionField.removeChild(regionField.firstChild);
    }

    regionField.appendChild(new Option());

    data.forEach((region: { Key: string, Value: string }) => {
      let option = new Option(region.Value, region.Key);

      //The region in the URL will be selected if it matches the region in the loop.
      if (countryInUrl == country && regionInUrl != null && regionInUrl == region.Key || selectedRegionValue == region.Key) {
        option.selected = true;
      }
      regionField.appendChild(option);
    });

  });
}

export async function populateCountryField(field: HTMLInputElement) {
  field.appendChild(new Option("", ""));
  await fetch(`/AjaxHelper/LoadListAsync/Country`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    }
  }).then(response => response.json()).then(data => {
    data.forEach((country: { Key: string, Value: string }) => {
      let option = new Option(country.Value, country.Key);

      field.appendChild(option);
    });
  });
}

export function addFlagsToCountryField(field: HTMLInputElement) {
  let options = field.querySelectorAll("option");
  options.forEach(option => {
    let value = option.value;
    let text = option.text;
    option.innerHTML = `<div class="flag flag-${value.toLowerCase()}"></div> ${text}`;
  });
}