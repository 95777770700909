import { updateCookieParam, checkAcceptCookies } from './cookies.js';
import { dontSubmitEmptyFormInputs } from './formTools.js';
export default function initHeaderSearchForm() {
  const searchForm = document.getElementById('SearchForm') as HTMLFormElement;
  if (!searchForm) return;


  let jobs = document.getElementById("search-jobs-btn");
  let jobseekers = document.getElementById("search-jobseekers-btn");
  let query = document.getElementById("search-query-btn");
  let jobTypesSelect = document.getElementById("JobTypes") as HTMLInputElement;
  let placeOrPostcode = document.getElementById("PlaceOrPostcode") as HTMLInputElement;
  let querysearch = document.getElementById("Query") as HTMLInputElement;
  let btnGPS = document.getElementById("btn-search-gps");
  let lat = document.getElementById("search-lat") as HTMLInputElement;
  let lng = document.getElementById("search-lng") as HTMLInputElement;
  let usingGPS = false;

  dontSubmitEmptyFormInputs(searchForm);

  //On change of jobtype or location, sets the name attribute of the element to the correct value. This is if the user submits the form and then cancels, then changes the values again.
  [jobTypesSelect, placeOrPostcode].forEach((element) => {
    element.addEventListener("change", function (e) {
      let target = e.target as HTMLInputElement;
      if (target.id == "JobTypes") {
        target.setAttribute("name", "jobTypes");
      } else if (target.id == "PlaceOrPostcode") {
        target.setAttribute("name", "Location");
      }
    });
  });

  [jobs, jobseekers, query].forEach((element) => {
    element.addEventListener("click", changeAction);
  });

  btnGPS.addEventListener("click", getGPS);

  function changeAction(e) {
    let selectedClass = "bg-gray-200";
    const element = e.target;
    const action = element.getAttribute("data-action");
    const searchForm = document.getElementById('SearchForm') as HTMLFormElement;
    searchForm.action = action;

    jobs.classList.remove(selectedClass);
    jobseekers.classList.remove(selectedClass);
    query.classList.remove(selectedClass);


    querysearch.classList.add("d-none");
    element.classList.add(selectedClass);
    jobTypesSelect.classList.remove("d-none");
    placeOrPostcode.classList.remove("d-none");
    placeOrPostcode.classList.remove("rounded-start");
    placeOrPostcode.setAttribute("placeholder", "Place or Postcode");
    placeOrPostcode.setAttribute("name", "location");
    btnGPS.classList.remove("d-none");

    if (element == query) {
      jobTypesSelect.classList.add("d-none");
      querysearch.classList.remove("d-none");
      placeOrPostcode.classList.add("d-none");
      placeOrPostcode.classList.add("rounded-start");
      placeOrPostcode.setAttribute("placeholder", "Listing Id");
      placeOrPostcode.setAttribute("name", "q");
      btnGPS.classList.add("d-none");
    }
  }

  function getGPS() {
    if (navigator.geolocation && !usingGPS) {
      navigator.geolocation.getCurrentPosition((pos) => {
        console.log(pos);
        placeOrPostcode.setAttribute("disabled", "true");
        placeOrPostcode.setAttribute("placeholder", "Using GPS");
        usingGPS = true;
        lat.value = pos.coords.latitude.toFixed(2);
        lng.value = pos.coords.longitude.toFixed(2);

        lat.removeAttribute("disabled");
        lng.removeAttribute("disabled");
        updateCookieParam("AnonUser", "Latitude", lat.value);
        updateCookieParam("AnonUser", "Longitude", lng.value);
      });
    } else if (usingGPS) {
      placeOrPostcode.removeAttribute("disabled");
      placeOrPostcode.setAttribute("placeholder", "Place or Postcode");
      lat.setAttribute("disabled", "true");
      lng.setAttribute("disabled", "true");
      usingGPS = false;
    }
  }
}