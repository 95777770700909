import { authUser } from './cookies.js';
import showModal from './showModal.js';
declare const bootstrap: any;
export default function initFeedbackForm() {
  let feedbackForm = document.getElementById('frmSendFeedback') as HTMLFormElement;
  if (feedbackForm == null) return;

  const feedbackModal = new bootstrap.Modal(document.getElementById('SendFeedback'), null);
  let submitFeedback = document.getElementById('SendFeedback_Submit') as HTMLButtonElement;
  let emailInput = feedbackForm.querySelector("#Email") as HTMLInputElement;
  let urlPath = feedbackForm.querySelector("#SendFeedback_URLPath") as HTMLInputElement;
  urlPath.value = window.location.pathname;

  if (authUser.UserName) {
    emailInput.value = authUser.UserName
  }

  submitFeedback.addEventListener("click", async function (e) {
    e.preventDefault();
    let rating = feedbackForm.querySelector(".star-rating") as HTMLElement;
    let data = new FormData(feedbackForm);
    if (rating) {
      let userRating = rating.getAttribute("data-rating");
      if (userRating == null) {
        rating.classList.add("animate__shakeX", "animate__animated");
        return;
      }
      data.append("Rating", userRating);
    }
    let response = await fetch('/Feedback/SendFeedback', { method: "POST", body: data });
    feedbackModal.hide();
    let json = await response.json();
    if (json.success == true) {
      showModal("Feedback Sent!", json.message);
    } else {
      showModal("Error", json.message);
    }
  });
}