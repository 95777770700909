import { authUser } from './cookies.js';
import { initConfirmLinks } from './initMisc.js'

export default function initPullFromServer() {
  let negativeFeedback = document.getElementById('NegativeFeedback');
  let adminButtons = document.getElementById('UserControlButtons');
  let userDetails = document.getElementById('UserDetails');

  if (negativeFeedback) {
    let userId = negativeFeedback.getAttribute("data-userid");
    pullFromServer(negativeFeedback, userId, "LoadJobseekerFeedbackAsync")
  }

  if (adminButtons) {
    let userId = adminButtons.getAttribute("data-userid");
    pullFromServer(adminButtons, userId, "LoadAdminButtonsAsync");
  }

  if (userDetails) {
    let userId = adminButtons.getAttribute("data-userid");
    pullFromServer(adminButtons, userId, "LoadUserDetailsAsync");
  }
}

//Pulls info from the server and populates the element with the data
export function pullFromServer(element: HTMLElement, userId: string, endpoint: string) {
  if (authUser && !authUser.Roles.includes("Admin")) return;

  if (element == null) return;

  let url = `/AjaxHelper/${endpoint}/${userId}`;



  fetch(url, {
    method: 'POST'
  }).then(response => response.text())
    .then(data => {
      if (data) {
        element.innerHTML += data;
      }

      //Init the anchor confirm links for the element if there are any
      initConfirmLinks(element);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
}