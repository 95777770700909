import showModal, { MessageType } from './showModal.js';
export interface AuthUserCookie {
  UserId: number;
  UserName: string;
  Roles: string[];
}

export interface AnonUserCookie {
  Country: string;
  City: string;
  PostalCodeId: number;
  PostCode: string;
  Region: string;
  RegionName: string;
  DistanceUnits: string;
  GeoStatusCode: number;
  Language: string;
  Latitude: number;
  Longitude: number;
  Roles: string[];
  UserId: number;
  UserName: string;
  ZoomLevel: number;
}

export interface AlertUserCookie {
  Items: UserAlert[];
}

export interface UserAlert {
  Message: string;
  Title: string;
  Type: string;
  ShowOnce: boolean;
  UserAlertId: number;
  PathName: string;
}
export interface FavouritesCookie {
  favourites: string[];
}

export interface SavedSearchCookie {
  JsonSearchParams: string,
  ListingType: number,
  Name: string,
  SavedSearchId: number,
  Url: string
}

export function getCookie(name: string): string | null {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null;
  return null;
}

function parseCookie<T>(cookieName: string): T | null {
  const cookieValue = getCookie(cookieName);
  if (!cookieValue) return null;

  try {
    return JSON.parse(decodeURIComponent(cookieValue)) as T;
  } catch (error) {
    console.error(`Error parsing cookie ${cookieName}:`, error);
    return null;
  }
}

export function updateCookieParam(cookieName: string, cookieParam: string, cookieValue: string) {
  let cookie = parseCookie<any>(cookieName);
  cookie[cookieParam] = cookieValue;
  let updateCookie = encodeURIComponent(JSON.stringify(cookie));
  document.cookie = `${cookieName}=${updateCookie}; path=/`;

}

function setCookie(name, value, days) {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function checkAcceptCookies() {

  let url = window.location.href.toLowerCase();
  if (url.includes("nocookiemodal=true")) {
    return;
  }

  let acceptedCookies = getCookie("AcceptCookies");
  if (acceptedCookies) {
  } else {
    let message = `This site uses cookies to deliver our services. By using our site, you acknowledge that you have read and understand our <a href="/home/privacy" title="Privacy Policy">Privacy Policy</a> and our <a href="/home/terms" title="Terms of Service">Terms of Service</a>. Your use of YardandGroom's Products and Services is subject to these policies and terms.`
    showModal("<i class='fa-solid fa-cookie-bite'></i> Cookies", message);
    setCookie("AcceptCookies", "acceptedcookies", 365);
  }
}

export function removeAlertFromCookie(alertId: number) {
  const alertsCookie = parseCookie<UserAlert[]>("UserAlerts");

  if (!alertsCookie) {
    return;
  }

  // Filter out the alert with the given alertId
  const updatedAlerts = alertsCookie.filter(alert => alert.UserAlertId !== alertId);

  // Update the cookie with the new alerts array
  setCookie("UserAlerts", JSON.stringify(updatedAlerts), 365);
}

export const userAlerts: UserAlert[] | null = parseCookie<UserAlert[]>("UserAlerts");
export const authUser: AuthUserCookie | null = parseCookie<AuthUserCookie>("AuthUser");
export const anonUser: AnonUserCookie | null = parseCookie<AnonUserCookie>("AnonUser");
export const favourites: number[] | null = parseCookie<number[]>("Favourites");
export const savedSearches: SavedSearchCookie[] | null = parseCookie<SavedSearchCookie[]>("SavedSearches");