import initLocationSearchInputs from './initLocationSearchInputs.js';
import initCountryRegionInputs, { populateCountryField, addFlagsToCountryField } from './initCountryRegionInputs.js';
declare const Choices: any;

//Updates the attribute names in a group of elements. E.g. an address.
export function updateInputGroupAttributes(group, count, clearInputFields = true) {
  let elements = group.querySelectorAll('input, label, select');

  elements.forEach(element => {
    if (element.id) {
      element.id = incrementAttribute(element.id, count);
    }
    if (element.getAttribute('name')) {
      element.setAttribute('name', incrementAttribute(element.getAttribute('name')!, count));
    }

    if (element.getAttribute('for')) {
      element.setAttribute('for', incrementAttribute(element.getAttribute('for')!, count));
    }
    if (element instanceof HTMLInputElement && clearInputFields) {
      element.value = "";
    }
  });
}

// Increment the number in the ID and name attributes
export function incrementAttribute(attrValue: string, newValue?: number): string {
  // Simplified regex to target the first group of digits
  return attrValue.replace(/\d+/, (match) => {
    if (newValue !== undefined) {
      return newValue.toString();
    }
    return (parseInt(match, 10) + 1).toString();
  });
}

//Returns the index of the li element
export function getIndexOfLi(li) {
  let index = 0;
  let currentElement = li;

  // Traverse previous siblings and count them
  while ((currentElement = currentElement.previousElementSibling) != null) {
    if (currentElement.tagName === 'LI') {
      index++;
    }
  }

  return index;
}

//Deletes an item from the list and updates all items after accordingly.
export function deleteItem(itemToRemove) {
  let index = getIndexOfLi(itemToRemove);

  let nextAddressItem = itemToRemove.nextElementSibling;

  itemToRemove.remove();

  while (nextAddressItem) {
    updateInputGroupAttributes(nextAddressItem, index, false);
    index++;

    let titleNumber = nextAddressItem.querySelector("h4 span");
    if (titleNumber) {
      titleNumber.innerHTML = index.toString();
    }

    nextAddressItem = nextAddressItem.nextElementSibling;
  }
}

export function initFormInputGroups() {
  let inputGroup = document.querySelector('.form-input-group') as HTMLElement;
  if (inputGroup == null) return;
  let addItemBtn = document.querySelector('.form-input-group-item-add') as HTMLAnchorElement;
  let deleteItemBtn = document.querySelectorAll('.form-input-group .form-input-group-item-delete') as NodeListOf<HTMLAnchorElement>;
  if (!addItemBtn) return;

  deleteItemBtn.forEach(button => {
    button.addEventListener("click", (e) => {
      let inputGroupItem = button.closest(".form-input-group-item") as HTMLElement;
      deleteItem(inputGroupItem);
    });
  });

  addItemBtn.addEventListener("click", (e) => {
    let inputGroupItems = inputGroup.querySelectorAll(".form-input-group-item");
    let inputGroupItem = inputGroupItems[0] as HTMLElement;
    let newInputGroupItem = inputGroupItem.cloneNode(true) as HTMLElement;
    let itemCount = inputGroupItems.length;

    if (inputGroup.id == "PreferredLocations") {
      initialiseAndAppendNewInputs(inputGroup, newInputGroupItem, itemCount);
      return;
    }

    //Reactivate delete button.
    let deleteButton = newInputGroupItem.querySelector(".form-input-group-item-delete") as HTMLElement;
    deleteButton.classList.remove("disabled");
    deleteButton.addEventListener("click", () => newInputGroupItem.remove());
    deleteButton.classList.remove("d-none");

    //Update used by text.
    let usedBy = newInputGroupItem.querySelector(".usedby");
    if (usedBy) {
      usedBy.innerHTML = "used by 0 listings";
    }

    //Update address number text.
    let titleNo = newInputGroupItem.querySelector("h4 span") as HTMLElement;
    if (titleNo) {
      titleNo.innerHTML = (inputGroupItems.length + 1).toString();
    }


    //This part is used to update all the id and name attributes of the elements in the new address item.
    updateInputGroupAttributes(newInputGroupItem, itemCount);

    let newItemLocationInput = newInputGroupItem.querySelector(".location-input-select") as HTMLElement;
    let newItemCountryInput = newInputGroupItem.querySelector(".address-country-select") as HTMLElement;

    let locationInputFormGroup = newItemLocationInput.closest(".form-group") as HTMLElement;

    //Update location input field.
    newItemLocationInput.parentElement.parentElement.remove();
    let newLocationInput = locationInputFormGroup.appendChild(newItemLocationInput.cloneNode(false)) as HTMLInputElement;
    newLocationInput.removeAttribute("hidden");
    newLocationInput.removeAttribute("data-choice");
    newLocationInput.classList.remove("choices__input");

    //Update country input field.
    let countryInputFormGroup = newItemCountryInput.closest(".form-group") as HTMLElement;
    newItemCountryInput.parentElement.parentElement.remove();
    let newCountryInput = countryInputFormGroup.appendChild(newItemCountryInput.cloneNode(false)) as HTMLInputElement;
    newCountryInput.removeAttribute("hidden");
    newCountryInput.removeAttribute("data-choice");
    newCountryInput.classList.remove("choices__input");


    //Turn the country input field into a choices field.
    populateCountryField(newCountryInput).then(() => {
      addFlagsToCountryField(newCountryInput);
      const choices = new Choices(newCountryInput);
    });

    //append the new address item to the list of addresses.
    inputGroup.appendChild(newInputGroupItem);

    //initialise the location search input box. This should be done AFTER the input box has been appended to the DOM.
    initLocationSearchInputs(newLocationInput);
  });
}

//Helper function to initialise a group of new inputs. Usually addresses or preferred locations.
export function initialiseAndAppendNewInputs(parentElement: HTMLElement, elementGroup: HTMLElement, count: number) {

  updateInputGroupAttributes(elementGroup, count);

  let locationInput = elementGroup.querySelector(".location-input-select") as HTMLElement;
  let countryInput = elementGroup.querySelector(".address-country-select") as HTMLInputElement;
  let regionInput = elementGroup.querySelector(".region-input-select") as HTMLInputElement;
  let newCountryInput, newLocationInput;

  [locationInput, countryInput].forEach(input => {
    let formGroup = input.closest(".form-group") as HTMLElement;
    //Update location input field.
    input.parentElement.parentElement.remove();
    let newInput = formGroup.appendChild(input.cloneNode(false)) as HTMLInputElement;
    newInput.removeAttribute("hidden");
    newInput.removeAttribute("data-choice");
    newInput.classList.remove("choices__input");

    if (input == countryInput && countryInput != null) {

      populateCountryField(newInput).then(() => {
        addFlagsToCountryField(newInput);
        const choices = new Choices(newInput);
      });
      newCountryInput = newInput;
    } else {
      newLocationInput = newInput;
    }
  });

  if (regionInput) {
    initCountryRegionInputs(newCountryInput);
  }

  parentElement.appendChild(elementGroup);
  initLocationSearchInputs(newLocationInput);
}

// Prevents empty form inputs from being submitted. This is so the query URL doesn't contain empty parameters.
export function dontSubmitEmptyFormInputs(form: HTMLFormElement) {
  form.addEventListener("submit", (e: SubmitEvent) => {
    let submitter = e.submitter as HTMLButtonElement;

    const formData = new FormData(form);

    // Iterate over all elements within the form
    Array.from(form.elements).forEach((element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
      if ('name' in element && element.name && element.value === '') {
        // Store original name in a data attribute and remove name attribute
        element.setAttribute('data-original-name', element.name);
        element.removeAttribute('name');
      } else if ('name' in element && element.name) {
      }
    });

    // Restore the name attributes so the form can be resubmitted correctly if needed
    setTimeout(() => {
      Array.from(form.elements).forEach(element => {
        if ('name' in element && !element.name && element.hasAttribute('data-original-name')) {
          element.name = element.getAttribute('data-original-name') || ''; // Restore the original name
          element.removeAttribute('data-original-name');
        }
      });
    }, 0);
  });
}