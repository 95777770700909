import { anonUser } from "./cookies.js";
declare global {
  interface Window {
    maps: { [key: string]: google.maps.Map };
  }
}

export default function initSettings() {
  let frmSettings = document.getElementById("frmSettings");
  if (!frmSettings) {
    frmSettings = document.getElementById("frmMap");
  }
  if (!frmSettings || !anonUser) return;


  let submitButton = frmSettings.querySelector("button[type='submit']") as HTMLButtonElement;
  let country = frmSettings.querySelector("#Country") as HTMLInputElement;
  let latitude = frmSettings.querySelector("#Latitude") as HTMLInputElement;
  let longitude = frmSettings.querySelector("#Longitude") as HTMLInputElement;
  let zoomLevel = frmSettings.querySelector("#ZoomLevel") as HTMLInputElement;
  let postalCodeId = frmSettings.querySelector("#PostalCodeId") as HTMLInputElement;
  let addressId = frmSettings.querySelector("#AddressId") as HTMLInputElement;
  let addressIdInput = frmSettings.querySelector("#AddressIdInput") as HTMLInputElement;

  let findLocationBtn = frmSettings.querySelector("#findLocation") as HTMLButtonElement;
  let settingsMap = window.maps["user-settings-map"] as any;

  submitButton.addEventListener("click", function (e) {
    zoomLevel.value = settingsMap.getZoom().toString();
  });

  //Add a marker to the map at the user's current location.
  let marker = new google.maps.Marker({
    position: new google.maps.LatLng(parseFloat(latitude.value), parseFloat(longitude.value)),
    map: settingsMap,
    draggable: true
  });

  marker.addListener("dragend", function (e) {
    latitude.value = e.latLng.lat().toString();
    longitude.value = e.latLng.lng().toString();
  });

  if (settingsMap) {
    settingsMap.addListener("zoom_changed", () => {
      var newZoom = settingsMap.getZoom();
      zoomLevel.value = newZoom;
    })
  }

  if (addressIdInput) {
    addressIdInput.addEventListener("change", (e) => {
      const target = e.target as HTMLSelectElement;
      var selectedOption = target.options[target.selectedIndex] as HTMLOptionElement;
      console.log(selectedOption.dataset.latitude, selectedOption.dataset.longitude)
      // Access data-latitude and data-longitude attributes
      latitude.value = selectedOption.dataset.latitude;
      longitude.value = selectedOption.dataset.longitude;
      let newCenter = new google.maps.LatLng(parseFloat(latitude.value), parseFloat(longitude.value));
      settingsMap.setCenter(newCenter);
      marker.setPosition(newCenter);
      addressId.value = selectedOption.value;
    });
  }

  if (postalCodeId) {
    postalCodeId.addEventListener("addItem", function (e) {
      let event = e as CustomEvent;
      let props = event.detail.customProperties;
      if (props.latitude && props.longitude) {
        let newCenter = new google.maps.LatLng(props.latitude, props.longitude);
        settingsMap.setCenter(newCenter);
        marker.setPosition(newCenter);
        latitude.value = props.latitude.toString();
        longitude.value = props.longitude.toString();
      }
    });
  }
  //Update the marker and map centre with the user's current location.
  if (findLocationBtn) {
    findLocationBtn.addEventListener("click", function (e) {
      e.preventDefault();
      if (navigator.geolocation && settingsMap != null) {
        navigator.geolocation.getCurrentPosition((pos) => {
          let newCenter = new google.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
          settingsMap.setCenter(newCenter);
          settingsMap.setZoom(10);
          //settingsMap.setZoom(parseInt(zoomLevel.value));
          marker.setPosition(newCenter);
          latitude.value = pos.coords.latitude.toString();
          longitude.value = pos.coords.longitude.toString();
        });
      }
    });
  }
}