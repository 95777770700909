import { completePayment } from './initPayment.js';
import { createOrder, processPayment } from './paypalHelpers.js';
import TransactionDetails from './transactionDetails.js';
import { notifyBot } from '../initMisc.js';
declare global {
  interface Window {
    ApplePaySession?: any; // Use "any" if no detailed types are available
  }
}

declare const paypal: any;
let current_customer_id;
let order_id;
let global_apple_pay_config;
let current_ap_session;
let applepay;
let apple_pay_email;
let paypal_order_id;
let applepay_payment_event;
let applePayTransactionDetails: TransactionDetails
let ipnIdElement;
let ipnId;
let intent = "capture";

export default function initApplePay(transactionDetails: any) {
  console.log("==Initialising Apple Pay Script===");

  const script = document.createElement('script');
  applePayTransactionDetails = JSON.parse(transactionDetails);

  ipnIdElement = document.getElementById("payment-ipnId") as HTMLInputElement;
  ipnId = ipnIdElement.value;

  console.log("==Transaction Details==");
  console.log(applePayTransactionDetails);
  script.src = "https://applepay.cdn-apple.com/jsapi/v1/apple-pay-sdk.js";
  script.async = true;
  script.onload = onApplePayLoaded;
  document.head.appendChild(script);
}

//APPLE PAY START POINT
//Called when the Apple Pay script has loaded
async function onApplePayLoaded() {
  console.log("==Apple Pay Script Loaded===");

  //STEP 1: Check if this device can use Apple Pay.
  let check_applepay = async () => {
    return new Promise<void>((resolve, reject) => {
      let error_message = "";
      if (!window.ApplePaySession) {
        error_message = "This device does not support Apple Pay";
      } else
        if (!window.ApplePaySession.canMakePayments()) {
          error_message = "This device, although an Apple device, is not capable of making Apple Pay payments";
        }
      if (error_message !== "") {
        reject(error_message);
      } else {
        resolve();
      }
    });
  };
  console.log("==Paypal Object:");
  console.log(paypal);
  //STEP 2: If this device supports Apple Pay, start rendering the Apple Pay button.
  check_applepay()
    .then(() => {
      applepay = paypal.Applepay();
      applepay.config()
        .then(applepay_config => {
          if (applepay_config.isEligible) {

            let paymentMethodsContainer = document.getElementById("payment-methods");
            let div = document.createElement("div");
            div.id = "applepay-button-container";
            paymentMethodsContainer.appendChild(div);
            div.innerHTML = '<apple-pay-button id="applepay_button" buttonstyle="black" type="plain" locale="en">';
            global_apple_pay_config = applepay_config;
            document.getElementById("applepay_button").addEventListener("click", onApplePayButtonClicked);
          }
        })
        .catch(applepay_config_error => {
          console.error('Error while fetching Apple Pay configuration:');
          console.error(applepay_config_error);
        });
    })
    .catch((error) => {
      console.error(error);
    });
}

//STEP 3: The user clicks the Apple Pay button. Create a new Apple Pay session.
async function onApplePayButtonClicked(e) {
  console.log("==Apple Pay button clicked==");

  const subtotal = (document.getElementById('payment-subtotal') as HTMLInputElement).value;
  const tax = (document.getElementById('payment-tax') as HTMLInputElement).value;
  const total = (document.getElementById('payment-total') as HTMLInputElement).value;
  const currency = (document.getElementById('payment-currency') as HTMLInputElement).value;
  const country = (document.getElementById('payment-country') as HTMLInputElement).value;

  const payment_request = {
    countryCode: global_apple_pay_config.countryCode,
    merchantCapabilities: global_apple_pay_config.merchantCapabilities,
    supportedNetworks: global_apple_pay_config.supportedNetworks,
    currencyCode: currency,
    requiredBillingContactFields: ["postalAddress"],
    ApplePayContactField: ["email"],
    total: {
      label: "YardandGroom",
      type: "final",
      amount: total,
    }
  };
  current_ap_session = new window.ApplePaySession(4, payment_request);
  current_ap_session.onvalidatemerchant = ap_validate;
  current_ap_session.onpaymentauthorized = onPaymentAuthorised;
  current_ap_session.begin();
}

//STEP 4: Validate the merchant using the validation URL provided by Apple Pay.
//Validate the merchant using the validation URL provided by Apple Pay.
async function ap_validate(e) {
  try {
    console.log("==Validating Apple Pay Merchant===");
    let validationResult = await applepay.validateMerchant({
      validationUrl: e.validationURL,
      displayName: "YardandGroom"
    });

    current_ap_session.completeMerchantValidation(validationResult.merchantSession);
    console.log("==Merchant Validation Successful==");
  } catch (validateError) {
    console.log("==Merchant Validation Failed==");
    current_ap_session.abort();
    notifyBot("Failed to validate Merchant during Apple Pay.");
  }
}

//STEP 5: User authorised the payment, create an order and confirm it with Apple Pay.
//Call Account/Payment/CreateOrder and pass it the transaction details to retrieve an order ID.
//Once retrieved an order id, pass it to apple pay using applepay.confirmOrder()
async function onPaymentAuthorised(event) {
  let paymentStep = "";
  try {
    applepay_payment_event = event.payment;

    //Create an order id
    console.log("==Creating Apple Pay Paypal Order Id==");
    paymentStep = "Creating Apple Pay Paypal Order Id";

    //STEP 5.1: Create order and get an order id.
    let createOrderResult = await createOrder(JSON.stringify(applePayTransactionDetails));
    console.log(`==Apple Pay Paypal Order Id ${createOrderResult.Id} Created==`);
    paypal_order_id = createOrderResult.Id;

    console.log("==Confirming the order with Apple Pay==");
    paymentStep = "Confirming the order with Apple Pay";

    //STEP 5.2: Confirm the order with Apple
    let appleConfirmOrderResponse = await applepay.confirmOrder({
      orderId: paypal_order_id,
      token: applepay_payment_event.token,
      billingContact: applepay_payment_event.billingContact
    });

    console.log("==Processing Apple Pay Payment==");
      paymentStep = "Processing Apple Pay Payment";
    //STEP 5.3: Send to the server to process the payment
    let processPaymentResult = await processPayment(paypal_order_id, ipnId);

    if (processPaymentResult.status == "COMPLETED") {
      console.log("==Apple Payment Success==");
      paymentStep = "Apple Payment Success";
      current_ap_session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
    } else {
      console.log("==Apple Payment Failure==");
      paymentStep = "Apple Payment Failure";
      current_ap_session.completePayment(window.ApplePaySession.STATUS_FAILURE);
    }

    console.log("==Completing Apple Pay Payment==");

    //STEP 6: Complete the payment, redirect
    completePayment(processPaymentResult, ipnIdElement.value);

  } catch (e) {
    current_ap_session.completePayment(window.ApplePaySession.STATUS_FAILURE);
    notifyBot(`Apple Pay payment failed on step:\n${paymentStep}\n\nError Details:\n\n${e}\n\nUser agent: \n\n ${navigator.userAgent}`);
    console.log(e);
  }
}