declare const bootstrap: any;
declare const htmx: any;
import showModal from './showModal.js';
export default function initHTMXExtras() {

  //ajax modals called by HTMX will be stored in here. for example the apply modal, or report abuse modal.
  var modals = {};
  document.body.addEventListener('htmx:afterSwap', function (event: CustomEvent) {

    if (event.target instanceof HTMLElement) {
      if (event.target.classList.contains('favourite-button')) {
        event.target.firstElementChild.classList.add('animate__animated', 'animate__bounceIn');
        //event.target.classList.add('animate__tada');
      }

      //If modal is already generated, show it, otherwise create it and store it in modals object.
      if (event.target.id == "modalShellContainer") {
        let srcElement = event.detail.requestConfig.elt
        let modalId = srcElement.getAttribute("data-modal-shell-id");
        if (modals[modalId] == null) {
          modals[modalId] = new bootstrap.Modal(document.getElementById(modalId));
        }
        modals[modalId].show();
      }
    }

    let response = event.detail.xhr.response;
    let target = event.detail.target;
    try {
      //If the response back contains a modal message, show it.
      let toJson = JSON.parse(response) as any;
      if (toJson.modalMessage) {
        showModal(null, toJson.modalMessage);
      }

      //If the event target is the job status, replace the ad with the new ad from the response.
      if (toJson.jobAd != null) {
        let jobAd = document.getElementById(`jobad-${toJson.listingId}`);
        let newJobAd = toJson.jobAd;
        if (jobAd != null) {

          jobAd.outerHTML = newJobAd;

          //jobAd.innerHTML = newJobAd.innerHTML;
          jobAd = document.getElementById(`jobad-${toJson.listingId}`);
          let liveIndicator = jobAd.querySelector(".live-indicator") as HTMLElement;
          let isLive = false;
          if (liveIndicator && !liveIndicator.textContent.includes("NOT LIVE")) {
            isLive = true;
          }
          let parent = jobAd.parentNode;
          let jobStatus = jobAd.querySelector(".job-select-status");
          let expiry = jobAd.querySelector(".job-select-expiry");
          let privacy = jobAd.querySelector(".job-select-privacy");
          let refreshBtn = jobAd.querySelector(".job-refresh-button");

          if (refreshBtn) {
            htmx.process(refreshBtn);
          }

          htmx.process(jobStatus);
          htmx.process(expiry);
          htmx.process(privacy);
          if (parent.firstChild !== jobAd && isLive) {
            parent.insertBefore(jobAd, parent.firstChild);
          }
          if (isLive) {
            jobAd.classList.add("animate__animated", "animate__flash")
          }
        }
      }
    } catch (e) {
      return null;
    }

  });

  document.body.addEventListener('htmx:beforeRequest', function (event: Event) {
    if (event.target instanceof HTMLElement) {
      //if htmx request target is the modal container, check if the modal exists in the modals object and open it.
      if (event.target.classList.contains("open-modal-shell")) {
        let modalId = event.target.getAttribute("data-modal-shell-id");
        if (document.getElementById(modalId) != null) {
          event.preventDefault();
          modals[modalId].show();
        }
      }

      if (event.target.classList.contains("search-input")) {
        if((event.target as HTMLInputElement).value.length < 6){
          event.preventDefault();
        }
      }
    }
  });

  document.body.addEventListener('htmx:afterRequest', function (event: CustomEvent) {

    //console.log(event.detail.elt);

  });

}