import { incrementAttribute } from './initMisc.js';
export default function initPreviousEmployers() {
  let listOfEmployers = document.getElementById('PreviousEmployerList') as HTMLElement;
  if (!listOfEmployers) return;
  let count = listOfEmployers.childNodes.length;
  let addEmployerButton = document.querySelector('.previousemployeradd') as HTMLAnchorElement;
  let removeEmployerButton = document.querySelectorAll('.previousemployerremove');
  let employerItem = listOfEmployers.querySelector('.employer') as HTMLElement;

  removeEmployerButton.forEach((button) => {
    button.addEventListener('click', function (e) {
      let employerItem = button.closest('.employer') as HTMLElement;
      employerItem.remove();
    });
  });

  //Add new employer item and update their ID and name attributes by incrementing the number
  addEmployerButton.addEventListener('click', function (e) {
    let newEmployerItem = employerItem.cloneNode(true) as HTMLElement;
    let elements = newEmployerItem.querySelectorAll('input, label, textarea');
    let deleteButton = newEmployerItem.querySelector(".previousemployerremove") as HTMLElement;

    deleteButton.addEventListener("click", function (e) {
      newEmployerItem.remove();
    });

    elements.forEach(element => {
      if (element.id) {
        element.id = incrementAttribute(element.id);
      }
      if (element.getAttribute('name')) {
        element.setAttribute('name', incrementAttribute(element.getAttribute('name')!));
      }

      if (element.getAttribute('for')) {
        element.setAttribute('for', incrementAttribute(element.getAttribute('for')!));
      }
      if (element instanceof HTMLInputElement) {
        element.value = "";
      }

    });

    listOfEmployers.appendChild(newEmployerItem);
  });
}