declare const bootstrap: any;
let modal: HTMLDivElement | null = null;
export enum MessageType {
  Primary,
  Secondary,
  Success,
  Danger,
  Warning,
  Info,
  Light,
  Dark,
  None
}

export default function showModal(title: string | null, message: string, type: MessageType = MessageType.None): void {

  let id = crypto.randomUUID();
  if (!document.getElementById(id)) {
    let modalDialog, modalHeader, modalContent, modalBody, modalFooter, closeButton;
    // Create modal element if it doesn't exist
    modal = document.createElement('div');
    modal.classList.add('modal', 'fade');
    modal.setAttribute('tabindex', '-1');
    modal.setAttribute('role', 'dialog');
    modal.id = id;

    // Create modal dialog
    modalDialog = document.createElement('div');
    modalDialog.classList.add('modal-dialog', 'modal-dialog-centered');
    modalDialog.setAttribute('role', 'document');

    // Create modal content
    modalContent = document.createElement('div');
    modalContent.classList.add('modal-content', 'mx-5');

    if (title == null) {
      switch (type) {
        case MessageType.Success || MessageType.Success.toString:
          title = "✅ Done";
          type = 2;
          break;
        case MessageType.Info:
          title = "ℹ️ Info";
          type = 5;
          break;
        case MessageType.Warning || "Warning":
          title = "⚠️ Warning";
          type = 4;
          break;
        case MessageType.Danger || "Danger":
          title = "❌ Error";
          type = 3;
      }
    }
    // Create modal header if title is not null
    if (title !== null) {
      modalHeader = document.createElement('div');
      modalHeader.classList.add('modal-header');
      const modalTitle = document.createElement('h5');
      modalTitle.classList.add('modal-title');

      modalTitle.innerHTML = title;
      modalHeader.appendChild(modalTitle);
      modalContent.appendChild(modalHeader);
    }

    if (type != MessageType.None) {

      let typeClass = MessageType[type].toLowerCase();
      if (modalHeader != null) {
        modalHeader.classList.add(`text-${typeClass}`);
      }
      modalContent.classList.add(`border-${typeClass}`);
    }

    // Create modal body
    modalBody = document.createElement('div');
    modalBody.classList.add('modal-body');
    modalContent.appendChild(modalBody);

    // Create modal footer
    modalFooter = document.createElement('div');
    modalFooter.classList.add('modal-footer');

    closeButton = document.createElement('button');
    closeButton.classList.add('btn', 'btn-outline-secondary', 'border-0');
    closeButton.setAttribute('type', 'button');
    closeButton.setAttribute('data-bs-dismiss', 'modal');
    closeButton.textContent = 'Close';
    modalFooter.appendChild(closeButton);
    modalContent.appendChild(modalFooter);

    // Assemble modal elements
    modalDialog.appendChild(modalContent);
    modal.appendChild(modalDialog);

    // Append modal to body
    document.body.appendChild(modal);
  }

  // Update modal body with new message
  const modalBody = modal.querySelector('.modal-body');
  if (modalBody) {
    modalBody.innerHTML = message;
  }

  // Show modal
  if (typeof bootstrap !== 'undefined') {
    const bootstrapModal = new bootstrap.Modal(modal);
    bootstrapModal.show();
  }

  let resendActivationEmail = modal.querySelector('.resend-activation-email') as HTMLAnchorElement;
  if (resendActivationEmail) {
    resendActivationEmail.addEventListener('click', function (e) {
      e.preventDefault();
      resendActivationEmail.classList.add("disabled");
      fetch('/Account/Home/ResendConfirmationEmailAsync', { method: "POST" }).then(response => response.text()).then((data) => {
        let result = document.createElement("div");
        result.classList.add("fw-bold", "text-success");
        result.innerHTML = data;
        resendActivationEmail.parentElement.append(result);
      }).catch(e => {
        resendActivationEmail.classList.remove("disabled");
        resendActivationEmail.innerHTML = "Failed to send email. Please try again.";
      });
    });
  }
}

export function showAlertModals() {
  let modalAlerts = document.querySelectorAll('.modal-alert');

  modalAlerts.forEach((modalAlert) => {
    const message = modalAlert.getAttribute('data-modal-message');
    const type = modalAlert.getAttribute('data-modal-message-type');
    showModal(null, message, MessageType[type]);
  });
}